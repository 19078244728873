/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';
/* html, body { height: 100%; } */
@font-face {
    font-family: 'Noir Pro';
    src: url('./assets/font/NoirPro-Regular.woff2') format('woff2'),
         url('./assets/font/NoirPro-Regular.woff') format('woff'),
         url('./assets/font/NoirPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'NoirPro';
    src: url('./assets/noir-pro-semi-bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
body { margin: 0; font-family: 'Noir Pro' , sans-serif; }
.toast-info {
	background-color: #29234A !important; 
	color: #fff !important; 
  }